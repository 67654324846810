<template>
    <b-overlay :show="showLoader">
        <div class="misc-wrapper text-center">
            <div class="misc-inner p-2 p-sm-3 w-100">
                <div v-if="elementsOptions.clientSecret != ''">
                    <b-row align-h="center" align-v="center" class="justify-content-center">
                        <b-col cols="12" sm="10">
                            <h1>Znesek: {{$convertPriceIntegerToFloat(paymentIntent.amount)}} €</h1>
                            <form>
                                <div>
                                    <b-spinner class="mt-1" v-if="buttonDisabled"></b-spinner>
                                </div>
                                <div class="mt-2" v-if="stripe">
                                    <stripe-element-payment @loading="loading" @element-ready="elementReady" @error="getError" ref="paymentRef" :elements-options="elementsOptions" :confirm-params="confirmParams" :testMode="true" :pk="stripe.publishableKey" locale="sl"/>
                                </div>
                                <b-button class="mt-2 button-disabled" :block="true" @click="pay" :disabled="buttonDisabled">Plačaj</b-button>
                            </form>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import {BOverlay, BRow, BCol, BButton, BSpinner} from 'bootstrap-vue'
    import { StripeElementPayment } from '@vue-stripe/vue-stripe'
    export default {
        components: { BOverlay, BRow, BCol, StripeElementPayment, BButton, BSpinner},
        data() {
            return {
                paymentIntent: {},
                showLoader: false,
                buttonDisabled: true,
                elementsOptions: {
                    clientSecret: '',
                    appearance: {
                        labels: 'above',
                        variables: {
                            colorPrimary: '#72A5D8',
                            colorBackground: '#ffffff',
                            colorText: '#000000',
                            colorDanger: '#ea5455',
                            borderRadius: '0px'
                        }
                    }, // appearance options
                    redirect: 'if_required'
                },
                confirmParams: {
                    return_url: `${window.location.origin}/success-pay` // success url
                },
                stripe: {publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}, //{publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY},
                element: null
            }
        },
        methods: {
            async loadStripeKey() {
                const rez = await this.$http.get('/api/user/v1/stripe_key')
                this.stripe = {publishableKey: rez.data}
            },
            async loadPaymentIntent() {
                const thisIns = this
                thisIns.showLoader = true

                await thisIns.$http.get(`/api/user/v1/stripe/payment_intent/${this.$route.params.order_id}`)
                    .then(res => {

                        if (res.data.status === 'succeeded') this.$router.push('/moja-narocila')

                        this.paymentIntent = res.data
                        this.elementsOptions.clientSecret = res.data.client_secret
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            loading(val) {
                this.showLoader = val
            },
            async pay() {
                await this.$refs.paymentRef.submit()
            },
            elementReady() {
                this.$refs.paymentRef.focus()
                this.buttonDisabled = false
            },
            getError(val) {
                this.$printError(val.message)
            }
            /*capture() {
                this.$router.replace({'path': '/success-pay', 'query': {payment_intent: this.paymentIntent.id, payment_intent_client_secret: '', redirect_status: ''}})
            }*/
        },
        async mounted() {
            await this.loadStripeKey()
            await this.loadPaymentIntent()
            document.getElementById('stripe-payment-element-errors').classList.add('mt-2', 'text-danger')
        }
    }
</script>

<style lang="scss" scoped>
    .button-disabled{
        border-radius: 20px;
    }
</style>>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>